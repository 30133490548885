import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage26() {
  const question = {
    title: 'Frage 26 von 35',
    headline: '',
    subline: 'Allein vs. Teamarbeit',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-red-circles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="red"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="turkis"
              from="Ich arbeite lieber alleine."
              to="Ich arbeite lieber im Team."
              index={26}
            />
          </div>
        </section>

        <MatchingNavigation
          color="red"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-27`}
          prev={`${AREA_CARE_PATH}/spielen/frage-25`}
          index={26}
        />

        <Icon position="1" name="alone" />
        <Icon position="4" name="team-3" />
      </main>
    </>
  )
}
